<template>
  <div class="container">
    <van-row v-if="list.length" class="list">
      <van-col v-for="(item,index) in list" :key="index" span="24">
        <div class="list-item">
          <div class="list-item-header">
            {{ item.course_cate_name }} {{ item.achievement_cate_finish_count }} / {{ item.achievement_cate_count }}
          </div>
          <div v-if="item.achievement_icon.length" class="list-item-inner">
            <van-row gutter="10">
              <van-col v-for="(icon,iconIndex) in item.achievement_icon" :key="iconIndex" span="8">
                <div class="list-item-box" :class="[icon.icon_enable ? '' :'list-item-box-gray']" @click="onToggle(index,iconIndex)">
                  <van-image class="list-item-box-img" :src="icon.icon_image" />
                  <div class="list-item-box-title van-multi-ellipsis--l2">{{ icon.icon_name }}</div>
                </div>
              </van-col>
            </van-row>
          </div>
        </div>
      </van-col>
    </van-row>
    <van-empty
      v-else
      :image="no_data"
      :description="noTips"
    />
  </div>
</template>
<script>
import { Empty } from 'vant'
import no_data from '@/assets/no_data.png'
import PageMixin from '@/mixins/page'

export default {
  name: 'StudentAchievement',
  components: {
    [Empty.name]: Empty
  },
  mixins: [PageMixin],
  data() {
    return {
      list: [],
      noTips: '您还没有成就哦~',
      no_data
    }
  },
  created() {
    this.onDetail()
  },
  methods: {
    onDetail() {
      this.$api.employee_scheduleachievement({ book_id: this.$route.query.book_id }).then(res => {
        this.list = res.data
      })
    },
    onToggle(index, iconIndex) {
      const { icon_id, icon_enable } = this.list[index].achievement_icon[iconIndex]

      this.$toast.loading({
        message: '发放中...',
        forbidClick: true
      })
      this.$api.employee_scheduleachievementtoggle({ book_id: this.$route.query.book_id, icon_id, enable: icon_enable === 1 ? 0 : 1 })
        .then(res => {
          this.$toast.success('发放成功')
          this.list = res.data
        })
        .catch(err => {
          this.$toast.fail('发放失败')
          console.error(err)
        })
        .finally(() => {
          this.onDetail()
        })
    }
  }
}
</script>

<style lang="less" scoped>

  .list{
    padding: 10px 15px;
    &-item{
      &-header{
        padding: 5px 10px 6px;
        font-size: 18px;
        font-weight: bold;
        color: #333;
      }
      &-inner{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 5px 10px;
        /deep/ .van-row{
          width: 100%;
        }
      }
      &-box{
        background: #fff;
        border-radius: 4px;
        overflow: hidden;
        margin-bottom: 10px;
        box-shadow: 0px 2px 4px 2px rgba(228, 228, 228, 0.5);
        &-img{
          width: 100px;
          height: 100px;
        }
        &-title{
          line-height: 1.8;
          padding: 0 5px;
          font-size: 14px;
          color: #666;
          min-height: 50px;
        }
        &-gray{
          position: none;
          opacity: .5;
        }
      }
    }
  }
</style>

